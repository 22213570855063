<template>
  <div class="orderManagementDetailsPageBox page-info-content">
    <Details-page :detail-obj="detailObj" :details-item-arr="detailsItemArr" :table-title="'商品详情'" :item-data="itemData" :list-data="listData" />
  </div>
</template>

<script>
import DetailsPage from '@/components/DetailsPage.vue'
import { impOrderInvoiceDetail } from '@/api/transactionData'
export default {
  components: { DetailsPage },
  data() {
    return {
      detailObj: {},
      itemData: [
        { label: '品名', prop: 'detailGoodsName' },
        { label: '规格', prop: 'detailGoodsSpecs' },
        { label: '发票代码', prop: 'detailInvoiceCode' },
        { label: '发票号码', prop: 'detailInvoiceNo' },
        { label: '数量', prop: 'detailQuantity' },
        { label: '金额(元)', prop: 'detailAmount' },
        { label: '单价(含税/元)', prop: 'detailPrice' },
        { label: '税率', prop: 'detailTaxRate' },
        { label: '税额(元)', prop: 'detailTaxAmt' }
      ],
      listData: [],
      detailsItemArr: [
        { label: '采购方企业', value: 'buyerCmpName', width: 200 },
        { label: '销售方企业', value: 'sellerCmpName', width: 200 },
        { label: '发票代码', value: 'invoiceCode', width: 200 },
        { label: '发票号码', value: 'invoiceNo', width: 200 },
        // { label: '发票校验码', value: 'checkCode', width: 200 },
        { label: '含税金额(元)', value: 'includeTaxAmt' },
        { label: '不含税金额(元)', value: 'excludeTaxAmt' },
        { label: '税额(元)', value: 'taxAmt' },
        { label: '税率(%)', value: 'taxRate' },
        { label: '发票附件', value: 'invoiceFile' },
        { label: '开票日期', value: 'openDate' }
      ]
    }
  },
  created() {
    if (this.$route.query.id) {
      this.getDetails()
    }
  },
  methods: {
    getDetails() {
      impOrderInvoiceDetail(this.$route.query.id, res => {
        this.detailObj = res.data
        if (res.data.impOrderInvoiceDetailRespList) this.listData = [...res.data.impOrderInvoiceDetailRespList]
      })
    }
  }
}
</script>
